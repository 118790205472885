import React from 'react'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404' />
    <div className='flex justify-center w-full'>
      <div className='flex max-w-2xl w-full flex-col justify-center items-center py-20'>
        <h1 className='mb-16'>NOT FOUND</h1>
        <p className='text-center'>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
